import React from "react";
import ReactPlayer from 'react-player'

export const Header = (props) => {
  return (
    <header id="header">
      <div className="container">
        <div className="col-xs-12 intro-text">
            <h1 style={{color: 'black'}}>
              {props.data ? props.data.title : "Loading"}
              <span></span>
            </h1>
            <div className='player-wrapper'>
              <ReactPlayer
                className='react-player'
                url={[
                  'https://www.youtube.com/watch?v=hGZAFSYC7eg',
                  'https://www.youtube.com/watch?v=ImOdQERre84'
                ]}
                width='100%'
                height='100%'
              />
            </div>
            <p style={{color: 'black'}}>{props.data ? props.data.paragraph : "Loading"}</p>
            <p></p>
            <div className='player-wrapper'>
                <ReactPlayer
                    controls
                    className='react-player'
                    url="https://vimeo.com/1047720312"
                    width='100%'
                    height='100%'
                />
            </div>
            <p></p>
            <a
              href="#services"
              className="btn btn-custom btn-lg page-scroll"
            >
              Learn More
            </a>{" "}
          </div>
      </div>
    </header>
  );
};
